import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicLayoutComponent from "../components/public_layout";
import VerifyEmail from "../views/verify_email";
import AuthorizedRoute from "../components/AuthorizedRoute";
import RootView from "../views/root";
import { Routes as routesPath } from "../config/routes";
import TermsView from "../views/terms";
import SiteLayout from "../components/layouts/layout";
import PolicyView from "../views/policy";
import FaqView from "../views/faq";
import GDPRView from "../views/gdpr";

import Contact from "../components/layouts/contact";
import BusinessView from "views/business/BusinessView";
import ProfileView from "views/profile";
import MessagesView from "views/messages";
import MessageDetailsView from "views/messages/MessageDetails";
import PrePayment from "views/stripe/landing";

const AppRoute = () => {
  const RedirectToShop = () => {
    return <Navigate to={routesPath.home} />;
  };

  return (
    <Suspense>
      <PublicLayoutComponent>
        <Routes>
          <Route path={routesPath.home} element={<RootView />} />
          <Route path={routesPath.profile} element={<ProfileView />} />
          <Route path={routesPath.messages} element={<MessagesView />} />
          <Route
            path={routesPath.messageDetails}
            element={<MessageDetailsView />}
          />
          <Route
            path={routesPath.terms}
            element={
              <SiteLayout>
                <TermsView />
              </SiteLayout>
            }
          />
          <Route
            path={routesPath.payment}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <PrePayment />
              </AuthorizedRoute>
            }
          />
          <Route path={`${routesPath.business}`} element={<BusinessView />} />
          <Route
            path={`${routesPath.business}/:nationality`}
            element={<BusinessView />}
          />
          <Route
            path={`${routesPath.business}/:nationality/:webUrl`}
            element={<BusinessView />}
          />
          <Route
            path={routesPath.privacy}
            element={
              <SiteLayout>
                <PolicyView />
              </SiteLayout>
            }
          />
          <Route
            path={routesPath.faq}
            element={
              <SiteLayout>
                <FaqView />
              </SiteLayout>
            }
          />
          <Route
            path={routesPath.gdpr}
            element={
              <SiteLayout>
                <GDPRView />
              </SiteLayout>
            }
          />
          <Route
            path={routesPath.verifyEmail}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <VerifyEmail />
              </AuthorizedRoute>
            }
          />
          <Route
            path={routesPath.contact}
            element={
              <AuthorizedRoute userRole={""} route={null}>
                <Contact />
              </AuthorizedRoute>
            }
          />
          <Route path="/*" element={<RedirectToShop />} />
        </Routes>
      </PublicLayoutComponent>
    </Suspense>
  );
};

export default AppRoute;
