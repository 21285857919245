import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import enBanner from "./locales/en/banner.json";
import enCommon from "./locales/en/common.json";
import enFaq from "./locales/en/faq.json";
import enGdpr from "./locales/en/gdpr.json";
import enPolicy from "./locales/en/policy.json";
import enTerms from "./locales/en/terms.json";
import businessProfile from "./locales/en/business-profile.json";
import enTable from "./locales/en/table.json";
import enForm from "./locales/en/form.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: { ...enCommon },
      form: { ...enForm },
      banner: { ...enBanner },
      faq: { ...enFaq },
      policy: { ...enPolicy },
      terms: { ...enTerms },
      gdpr: { ...enGdpr },
      businessProfile: { ...businessProfile },
      table: { ...enTable },
    },
  }, // Where we're gonna put translations' files
  lng: "en", // Set the initial language of the App
});
