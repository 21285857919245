import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import client from "../client";

export function useCustomerFinanceBankCardCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankCardCreate,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceBankCardList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankCardList,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceBankCardDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankCardDelete,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceBankAccountCreate() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankAccountCreate,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceBankAccountList() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankAccountList,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceBankAccountDelete() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.bankAccountDelete,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceCreatePaymentIntent() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.createPaymentIntent,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceConfirmPaymentIntent() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.confirmPaymentIntent,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinanceRetrievePaymentIntent() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.retrievePaymentIntent,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerFinancePaymentConfig() {
  let [serverError, setServerError] = useState<string | null>(null);

  const { mutate, isLoading } = useMutation(
    client.customerFinance.paymentConfig,
    {
      onError: (error: Error) => {
        const {
          response: { data },
        }: any = error ?? {};

        if (data.non_field_errors) {
          setServerError(data.non_field_errors);
        } else if (data) {
          setServerError(error.message);
        }
      },
    }
  );

  return { mutate, isLoading, serverError, setServerError };
}
