export const Routes = {
  home: "/",
  login: "/login",
  profile: "/profile",
  messages: "/messages",
  messageDetails: "/message-details",
  logout: "/logout",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  checkout: "/orders/checkout",
  verifyEmail: "/verify-email",
  terms: "/terms",
  privacy: "/privacy",
  faq: "/faq",
  gdpr: "/gdpr",
  contact: "/contact",
  help: "/help",
  business: "/business",
  payment: "/reauth",
  user: {
    ...routesFactory("/users"),
  },
};

function routesFactory(endpoint: string) {
  return {
    list: `${endpoint}`,
    create: `${endpoint}/create`,
    editWithoutLang: (slug: string, shop?: string) => {
      return shop
        ? `/${shop}${endpoint}/${slug}/edit`
        : `${endpoint}/${slug}/edit`;
    },
    edit: (slug: string, language: string, shop?: string) => {
      return shop
        ? `/${language}/${shop}${endpoint}/${slug}/edit`
        : `${language}${endpoint}/${slug}/edit`;
    },
    translate: (slug: string, language: string, shop?: string) => {
      return shop
        ? `/${language}/${shop}${endpoint}/${slug}/translate`
        : `${language}${endpoint}/${slug}/translate`;
    },
    details: (slug: string) => `${endpoint}/${slug}`,
  };
}
