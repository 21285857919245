import { ArrowNext, ArrowPrev } from "components/icons";
import RCPagination, { PaginationProps } from "rc-pagination";
import "rc-pagination/assets/index.css";

const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <RCPagination
      nextIcon={<ArrowNext />}
      prevIcon={<ArrowPrev />}
      {...props}
    />
  );
};

export default Pagination;
